export interface ParsedJwt {
  exp: number
  [name: string]: unknown
}

export const parseJwt = (token: string): ParsedJwt => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const isJwtExpiredDebug = (token: string): boolean => {
  if (!token) return true

  try {
    const decodedToken = parseJwt(token)
    const currentTime = Date.now() / 1000 // Current time in seconds
    const issuanceTime = decodedToken.exp - 86400 // 86400 seconds = 24 hours

    // Check if 1 minute has passed since issuance
    if (currentTime > issuanceTime + 60) {
      console.debug('More than 1 minute has passed since derived issuance time.')
      return true
    }

    // Standard expiration check
    const isExpired = decodedToken.exp < currentTime
    console.debug('Is token expired based on exp:', isExpired)

    return isExpired
  } catch (error) {
    console.error('Error decoding token:', error)
    return true
  }
}
const debug = false

export const isJwtExpired = (token: string): boolean => {
  if (!token) return true
  if (debug) return isJwtExpiredDebug(token)

  try {
    const decodedToken = parseJwt(token)
    const currentTime = Date.now() / 1000 // Current time in seconds

    return decodedToken.exp < currentTime
  } catch (error) {
    console.error('Error decoding token:', error)
    return true
  }
}
