import React, { useMemo } from 'react'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from './ui/pagination'
import { cn } from '../lib/utils'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select'

interface AnalyticPaginationProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  className?: string
  showPageInfo?: boolean
  itemsPerPage: number
  onItemsPerPageChange: (value: number) => void
  totalItems: number
}

export function AnalyticPagination({
  currentPage,
  totalPages,
  onPageChange,
  className,
  showPageInfo = true,
  itemsPerPage,
  onItemsPerPageChange,
  totalItems
}: AnalyticPaginationProps) {
  const canGoPrevious = currentPage > 1
  const canGoNext = currentPage < totalPages

  const visiblePages = useMemo(() => {
    const pages: (number | 'ellipsis')[] = []

    if (totalPages <= 6) {
      // Show all pages if total is 6 or less
      return Array.from({ length: totalPages }, (_, i) => i + 1)
    }

    // Show first 3 pages
    for (let i = 1; i <= 3; i++) {
      pages.push(i)
    }

    // Add ellipsis
    pages.push('ellipsis')

    // Show last 3 pages
    for (let i = totalPages - 2; i <= totalPages; i++) {
      pages.push(i)
    }

    return pages
  }, [totalPages])

  if (totalPages <= 1) return null

  return (
    <div className={cn('flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between px-2', className)}>
      <div className="flex items-center gap-4">
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                onClick={() => canGoPrevious && onPageChange(currentPage - 1)}
                className={cn(
                  'cursor-pointer select-none',
                  !canGoPrevious && 'pointer-events-none opacity-50'
                )}
              />
            </PaginationItem>

            {visiblePages.map((page, idx) => (
              page === 'ellipsis' ? (
                <PaginationItem key={`ellipsis-${idx}`}>
                  <PaginationEllipsis />
                </PaginationItem>
              ) : (
                <PaginationItem key={page}>
                  <PaginationLink
                    onClick={() => onPageChange(page)}
                    isActive={currentPage === page}
                    className="cursor-pointer"
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              )
            ))}

            <PaginationItem>
              <PaginationNext
                onClick={() => canGoNext && onPageChange(currentPage + 1)}
                className={cn(
                  'cursor-pointer select-none',
                  !canGoNext && 'pointer-events-none opacity-50'
                )}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>

        {showPageInfo && (
          <div className="text-sm text-muted-foreground text-nowrap">
            Page {currentPage} of {totalPages}
          </div>
        )}
      </div>

      <div className="flex items-center gap-3 text-sm text-muted-foreground">
        <div className="flex items-center gap-2">
          <span>Rows per page</span>
          <Select
            value={itemsPerPage.toString()}
            onValueChange={(value) => onItemsPerPageChange(Number(value))}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder="100" />
            </SelectTrigger>
            <SelectContent>
              {[100, 200, 500].map((pageSize) => (
                <SelectItem key={pageSize} value={pageSize.toString()}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          Showing {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)} - {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems}
        </div>
      </div>
    </div>
  )
} 