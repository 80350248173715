import React from 'react'
import { Route, Switch, Redirect } from 'wouter'
import LoginPage from './pages/LoginPage'
import RequestPasswordResetPage from './pages/RequestPasswordResetPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import TransmissionLinePage from './pages/TransmissionLinePage'
import Navbar from './components/organisms/Navbar'
import { useRecoilValue } from 'recoil'
import { isLoggedInState } from './state/authState'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

const App: React.FC = () => {
  const isLoggedIn = useRecoilValue(isLoggedInState)

  return (
    <QueryClientProvider client={queryClient}>
      <div className='App'>
        <Navbar />
        <Switch>
          <Route path='/login'>
            {isLoggedIn ? <Redirect to='/dashboard' /> : <LoginPage />}
          </Route>
          <Route path='/requestPasswordReset'>
            <RequestPasswordResetPage />
          </Route>
          <Route path='/resetPassword'>
            <ResetPasswordPage />
          </Route>
          <Route path='/'>
            {isLoggedIn ? <Redirect to='/dashboard' /> : <Redirect to='/login' />}
          </Route>
          <Route path='/dashboard'>
            {isLoggedIn ? <TransmissionLinePage /> : <Redirect to='/login' />}
          </Route>
          {/* More routes here. */}
        </Switch>
      </div>
    </QueryClientProvider>
  )
}

export default App
